/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Text, SeparateLine, SeparateLineWrap, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Místo a čas"}>
        <SiteHeader />

        <Column className="pb--50 pt--80" name={"uvod"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32019/a81d9becffab4932bbfb5fea7dd9c17c_e=238x205x1227x1843_s=660x_.JPEG"} sizes={"100vw"} style={{"maxWidth":670}} srcSet={"https://cdn.swbpg.com/t/32019/a81d9becffab4932bbfb5fea7dd9c17c_e=238x205x1227x1843_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/32019/a81d9becffab4932bbfb5fea7dd9c17c_e=238x205x1227x1843_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/32019/a81d9becffab4932bbfb5fea7dd9c17c_e=238x205x1227x1843_s=860x_.JPEG 860w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box title-box--center ff--1 fs--86 lh--1" content={"<b><font color=\"#020202\">Dominik &amp; Lukáš</font></b>"}>
                    </Title>

                    <Subtitle className="subtitle-box fs--62 lh--1 mt--0" content={"<font color=\"#121212\">30. září 2023<br></font>&nbsp;<br>"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80" name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--86 lh--14" style={{"maxWidth":1000}} content={"Budeme se brát v Las Vegas. <br>Svatba pak bude pokračovat <br>30. září ve 14:00 ve Svinech. <br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"1paydkm6l3e"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--center ff--3 fs--24 w--600 lh--2" style={{"maxWidth":1000}} content={"Když nám zde na sebe necháte email, pošleme Vám odkaz na sledování obřadu, abyste s námi mohli být i na dálku v Americe. <br>Na všechny se pak budeme těšit osobně ve Svinech.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Karlův most, Praha"} src={"https://cdn.swbpg.com/t/32019/f66d7639684341cdbef76c9a9889fdb4_e=206x375x1227x1638_s=660x_.JPEG"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/32019/f66d7639684341cdbef76c9a9889fdb4_e=206x375x1227x1638_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/32019/f66d7639684341cdbef76c9a9889fdb4_e=206x375x1227x1638_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/32019/f66d7639684341cdbef76c9a9889fdb4_e=206x375x1227x1638_s=860x_.JPEG 860w"} lightbox={false} position={null} description={"Karlův most, Praha"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center mt--50" anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/32019/471ff37030794054941e47b6e1b09839_e=69x73x1400x1869_s=860x_.JPEG"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/32019/471ff37030794054941e47b6e1b09839_e=69x73x1400x1869_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/32019/471ff37030794054941e47b6e1b09839_e=69x73x1400x1869_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/32019/471ff37030794054941e47b6e1b09839_e=69x73x1400x1869_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/32019/471ff37030794054941e47b6e1b09839_e=69x73x1400x1869_s=1400x_.JPEG 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/32019/767328502ac64865ad4c6f45c9197035_e=40x52x1689x2256_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/32019/767328502ac64865ad4c6f45c9197035_e=40x52x1689x2256_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32019/767328502ac64865ad4c6f45c9197035_e=40x52x1689x2256_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32019/767328502ac64865ad4c6f45c9197035_e=40x52x1689x2256_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32019/767328502ac64865ad4c6f45c9197035_e=40x52x1689x2256_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"3r6uxy18rj7"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--80 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102" content={"21. září 2023, 19:30 <span style=\"font-style: italic;\">seč</span><br>"}>
              </Title>

              <Title className="title-box title-box--center fs--72 mt--10" content={"Las Vegas, USA<br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--pbtn2 btn-box--shape2 btn-box--cColor1 btn-box--filling3 btn-box--justify ff--1 fs--24 mt--50" innerClassName="pb--02 pl--0 pr--0 pt--0" href={"/ubytovani#f7u5e5u64j"} style={{"backgroundColor":"var(--white)"}} content={"<font color=\"#0a00ac\">Zde se přihlásíte k zaslání odkazu na online přenos z obřadu</font>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"fj5a4bs3n5f"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--80 pt--80" name={"ohfnmny6ya"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102" content={"30. září 2023, 14:00<br>"}>
              </Title>

              <Title className="title-box fs--72" content={"Sviny, Česká Republika<br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--pbtn2 btn-box--shape2 btn-box--cColor1 btn-box--filling3 btn-box--justify fs--24 mt--50" innerClassName="pb--02 pl--0 pr--0 pt--0" href={"/potvrdit-ucast#potvrdit-ucast-2"} style={{"backgroundColor":"var(--white)"}} content={"<font color=\"#0a00ac\">Zde nám prosím dejte vědět, kolik Vás dorazí&nbsp;</font>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"90wieivetyn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--60 pt--60" name={"0abzo36z1yno"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--30" style={{"maxWidth":1000}} content={"Děkujeme, že nám dáte do konce července vědět, jestli se zúčastníte, kolik Vás přijde a jestli budete chtít zajistit ubytování. <br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"cnvg5ghr2a"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"8v72c2xsrad"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--86" content={"Program<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left fs--22" content={"14:00 - Příjezd hostů <br>15:00 - Zahájení krájením dortu <br>16:00 - Raut, který bude k dispozici po celý večer<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"wudrcxhhu9"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--60 pt--60" name={"rbbbq23jmsl"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--20" style={{"maxWidth":1000}} content={"Největším dárkem pro nás bude, když tento den strávíte s námi a užijete si ho. <br>Chce-li být snad někdo štědrý, radu pro něj máme, že cestujeme rádi světem, to je o nás známé. Nenoste nám tedy prosím hrnce ani cukřenky, raději nám přispějte na pobyt či letenky. <br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}